































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class FooterWeb extends Vue {
  private win = window;
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
  private irLink(link: any) {
    window.open(link, "_blank");
  }
}
